body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .App {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .App-header {
    background-color: #282c34;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  button {
    background-color: #61dafb;
    color: #282c34;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: #21a1f1;
  }
  
  .App-footer {
    background-color: #20232a;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 14px;
  }
  